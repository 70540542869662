import React from "react";
import DefaultUser from "../../../../Assets/defaultuser.jpeg";
import { Check } from "../Check";
import { Open } from "../Open";
import "./style.css";

export function SupplierCard({ supplier, onClick, handleAbout, checked }) {
  return (
    <div className="supplier-card">
      <div className="supplier-card__header">
        <Open
          width={16}
          height={16}
          color={"#c6c6c6"}
          onClick={() =>
            handleAbout(supplier.anuncio_id, supplier.anunciante.anunciante_id)
          }
        />

        <Check
          width={20}
          height={20}
          color={checked ? "#BE3E70" : "#c6c6c6"}
          onClick={() => onClick(supplier.anuncio_id)}
        />
      </div>

      <div className="supplier-card__icon">
        <img src={supplier.anuncio_logo ?? DefaultUser} alt="logo" />
      </div>

      <div className="supplier-card__content">
        <p>{supplier.anuncio_nome?.slice(0, 40) ?? "(sem nome)"}</p>
      </div>

      <div className="supplier-card__label">
        <p>{supplier.anuncio_descricao ?? "(sem serviço)"}</p>
      </div>
    </div>
  );
}
