import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import Footer from "../../../components/Footer/Footer";
import { useForm } from "react-hook-form";
import api from "../../../Services/api";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useHistory } from "react-router-dom";
// import { Snackbar } from "@material-ui/core";
// import { Alert } from "@material-ui/lab";
import { useState } from "react";
import newBaseApi from "../../../Services/newBaseApi";

export function SignIn() {
  // const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { isSubmitting },
  } = useForm();
  const { login } = useContext(AuthContext);
  const history = useHistory();
  const [error, setError] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const recoveryPassword = async (event) => {
    event.preventDefault();
    setError(undefined);
    setMessage(undefined);
    const email = getValues("email");
    if (!email) {
      setError("Digite o seu e-mail, para recuperar a senha");
    }

    try {
      const response = await newBaseApi.post("/consumidor/password_reset", {
        email,
      });
      setMessage("Um link de recuperação foi enviado para o seu e-mail");
    } catch (error) {
      setError(error.response?.data.error || error.message);
      console.error(
        "Erro ao recuperar senha:",
        error.response?.data || error.message
      );
    }
  };

  const onSubmit = async (data) => {
    try {
      setError(undefined);
      setMessage(undefined);
      const response = await newBaseApi.post("/consumidor/login", data);

      if (response.data && response.data.token) {
        login(response.data);
        history.push("/dashboard");
      } else {
        console.error("API:", response.data);
        setError(response.data.message);
        // setOpen(true);
      }
    } catch (error) {
      setError(error.response?.data.message || error.message);
      console.error("Erro no login:", error.response?.data || error.message);
    }
  };

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  // };

  return (
    <>
      <div className="container-fluid mt-60">
        <div className="row mt-20">
          <div className="col-md-12 text-center">
            <h1 className="mt-30 login__title">Login de Acesso</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 offset-md-4 mt-30">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group text-center login-block">
                <label htmlFor="usuario">Email</label>

                <div className="user">
                  <FontAwesomeIcon className="user-icon" icon={faUser} />
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    {...register("email", {
                      required: "O email é obrigatório",
                    })}
                  />
                </div>
              </div>

              <div className="form-group text-center login-block">
                <label htmlFor="senha">Senha</label>

                <div className="user">
                  <FontAwesomeIcon className="user-icon" icon={faLock} />
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    {...register("password", {
                      required: "A senha é obrigatória",
                    })}
                  />
                </div>
              </div>

              <div className="form-group text-center login-block">
                <p>
                  Não possui conta?{" "}
                  <a href="/sign-up" target="_blank" className="custom-link">
                    Cadastre-se agora!
                  </a>
                </p>
              </div>

              <div className="form-group text-center login-block">
                <p>
                  Não consegue entrar?{" "}
                  <button
                    type="button"
                    onClick={recoveryPassword}
                    className="custom-link"
                  >
                    Recupere sua senha.
                  </button>
                </p>
              </div>

              {error && (
                <p
                  className="error-message"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {error}
                </p>
              )}

              {message && (
                <p
                  className="error-message"
                  style={{
                    textAlign: "center",
                    color: "green",
                  }}
                >
                  {message}
                </p>
              )}

              <div class="form-group text-center">
                <button className="btn btn-login" disabled={isSubmitting}>
                  {isSubmitting ? "Entrando" : "Entrar"}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="row mt-120">
          <Footer></Footer>
        </div>
      </div>

      {/* <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          E-mail ou senha inválidos!
        </Alert>
      </Snackbar> */}
    </>
  );
}

