import React from "react";
import "./../css/informacoes.css";

export default function Informacoes(props) {
  const { data } = props;

  return (
    <div className="informacoes-body">
      <div>
        <h1>Serviços</h1>
        <p> {data.ramo_negocio} </p>
      </div>
      <div>
        <h1>Contato/Telefone</h1>
        <p> {data.contato} </p>
      </div>
    </div>
  );
}
