import React from "react";
import { Link } from "react-router-dom";
import cartoes from "./../../Assets/bandeiras.png";
import "./Footer.css";

export default function Footer({ noShadow }) {
  return (
    <footer
      style={{ boxShadow: noShadow ? "none" : "0px 2px 22px 0px #a7a7a7" }}
    >
      <div className="footer-container">
        <div className="footer-description">
          <h1>O QUE É O LUP?</h1>
          <p>
            Uma plataforma para criar e realizar qualquer tipo de evento, com
            guias específicos para marinheiros de primeira viagem e liberdade
            para festeiros profissionais. Com a vantagem de te colocar em
            contato com os melhores da sua cidade, de maneira fácil e simples.
          </p>
          <p>Razão Social: LUP AGENCIA DE PUBLICIDADE LTDA </p>
          <p>CNPJ: 31.436.839/0001-09 </p>
        </div>
        <div className="footer-list">
          <ul>
            <li>
              <Link to="/">Home</Link>{" "}
            </li>
            <li>
              <Link to="/quem-somos">Quem Somos</Link>{" "}
            </li>
            <li>
              <Link to="/app-links">Links para o Aplicativo</Link>{" "}
            </li>
            <li>
              <a href="mailto:contato@lup.com.br">Contato</a>
            </li>

            <li>
              <a href="/politica-privacidade/app">Política de Privacidade</a>{" "}
            </li>
          </ul>
          {/* <ul>
                    <li><a href="https://app.lup.com.br/anunciante/login.html">Login Anunciante</a></li>
                    <li><a href="https://app.lup.com.br/consumidor/login.html">Login Consumidor</a></li>
                    <li><Link to="/convite">Consultar Convite </Link></li>
                    <li><Link to="/filter/none">Consultar Anunciantes</Link></li>
                    <li><Link to="/eventos-publicos">Eventos Públicos</Link></li>
                </ul> */}
        </div>
      </div>
      <div className="cartoes">
        <img src={cartoes} alt="bandeiras de catões"></img>
      </div>
    </footer>
  );
}

