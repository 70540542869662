import React, { useEffect, useState } from "react";
import "./css/Filtro.css";
import casados from "../../Assets/casados.png";
import aniversario from "../../Assets/aniversario.png";
import corporativo from "../../Assets/corporativo.png";
import happy from "../../Assets/happy.png";

import api from "../../Services/api";
import { useHistory } from "react-router-dom";

export default function Filtro() {
  const history = useHistory();

  const [estados, setEstados] = useState([]);
  const [estadoEscolhido, setEstadoEscolhido] = useState("");
  const [cidades, setCidades] = useState([]);
  const [escolhaCidade, setEscolhaCidade] = useState("");
  const [tipos, setTipos] = useState([]);
  const [escolhaTipos, setEscolhaTipos] = useState(""); // Tipo do Evento
  const [ramo, setRamo] = useState([]);
  const [escolhaRamo, setEscolhaRamo] = useState(""); // Tipo do Serviço
  const [temas, setTemas] = useState([]);
  const [escolhaTema, setEscolhaTema] = useState("");
  const [imgFilter, setImgFilter] = useState(casados);

  useEffect(() => {
    async function loadEstados() {
      const response = await api.get("/estados");
      setEstados(response.data);
    }

    async function loadTipos() {
      const response = await api.get("/tiposeventos");
      setTipos(response.data);
    }

    async function loadRamo() {
      const response = await api.get("/ramo");
      setRamo(response.data);
    }

    async function loadTemas() {
      const response = await api.get("/temaestabelecimento");
      setTemas(response.data);
    }

    loadEstados();
    loadTipos();
    loadRamo();
    loadTemas();
    getUserLocation();
  }, []);

  const getUserLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const locationResponse = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
          );
          const locationData = await locationResponse.json();

          const { state, town } = locationData.address;

          if (!state || !town) {
            console.error("Erro: Não foi possível obter o estado ou a cidade.");
            return;
          }

          const stateData = await api.get("/estados");
          const foundState = stateData.data.find(
            (item) => item.nome.toLowerCase() === state.toLowerCase()
          );

          if (foundState) {
            setEstadoEscolhido(foundState.id);

            const citiesResponse = await api.get(`/cidades/${foundState.id}`);
            setCidades(citiesResponse.data);

            const foundCity = citiesResponse.data.find(
              (item) => item.nome.toLowerCase() === town.toLowerCase()
            );

            if (foundCity) {
              setEscolhaCidade(foundCity.id);
            }
          }
        } catch (error) {
          console.error("Erro ao obter localização do usuário:", error);
        }
      });
    } else {
      console.error("Geolocalização não suportada pelo navegador.");
    }
  };

  function handleSelect(e) {
    const id = e.target.value;
    setEscolhaCidade("");
    setEstadoEscolhido(id);

    async function loadCidades() {
      const response = await api.get(`/cidades/${id}`);
      setCidades(response.data);
    }

    if (id) {
      loadCidades();
    }
  }

  function filtrar(tipo) {

    let filtrosDados = ""
    if(tipo){
      filtrosDados = {
        estado: estadoEscolhido || "",
        cidade: escolhaCidade || "",
        tipoServico: escolhaRamo || "",
        tema: escolhaTema || "",
        tipoEvento: tipo, // Sempre vazio
      };
    } else {
      filtrosDados = {
        estado: estadoEscolhido || "",
        cidade: escolhaCidade || "",
        tipoServico: escolhaRamo || "",
        tema: escolhaTema || "",
        tipoEvento: escolhaTipos, // Sempre vazio
      };
    }

    const queryParams = new URLSearchParams(filtrosDados)
    
  
    history.push(`/filter/${queryParams.toString()}`);
  }
  

  return (
    <section id="filtro">
      <div className="container-filtro">
        <div className="title-filtro">
          <h1>NO LUP O SEU EVENTO É COMPLETO</h1>
          <h2>Monte, organize e convide. Tudo em um lugar só.</h2>
        </div>
        <div className="body-filtro">
          <div className="type-filter">
            <ul>
              <li
                className={
                  imgFilter === casados
                    ? "type-select-filter filter-select"
                    : "filter-select"
                }
                onMouseOver={() => setImgFilter(casados)}
                onClick={() => filtrar(4)}
              >
                <h1>Casamento</h1>
              </li>
              <li
                className={
                  imgFilter === aniversario
                    ? "type-select-filter filter-select"
                    : "filter-select"
                }
                onMouseOver={() => setImgFilter(aniversario)}
                onClick={() => filtrar(1)}
              >
                <h1>Aniversário</h1>
              </li>
              <li
                className={
                  imgFilter === corporativo
                    ? "type-select-filter filter-select"
                    : "filter-select"
                }
                onMouseOver={() => setImgFilter(corporativo)}
                onClick={() => filtrar(22)}
              >
                <h1>Corporativo</h1>
              </li>
              <li
                className={
                  imgFilter === happy
                    ? "type-select-filter filter-select"
                    : "filter-select"
                }
                onMouseOver={() => setImgFilter(happy)}
                onClick={() => filtrar(10)}
              >
                <h1>Happy Hour</h1>
              </li>
            </ul>
          </div>
          <div className="img-filter">
            <img src={imgFilter} alt="Imagem do filtro" />
          </div>
          <div className="form-filter">
            <select onChange={handleSelect} value={estadoEscolhido}>
              <option value="">Selecione o Estado</option>
              {estados.map((estado) => (
                <option value={estado.id} key={estado.id}>
                  {estado.nome}
                </option>
              ))}
            </select>

            <select
              onChange={(e) => setEscolhaCidade(e.target.value)}
              value={escolhaCidade}
            >
              <option value="">Selecione a cidade</option>
              {cidades.map((cidade) => (
                <option value={cidade.id} key={cidade.id}>
                  {cidade.nome}
                </option>
              ))}
            </select>

            <select onChange={(e) => setEscolhaTipos(e.target.value)} value={escolhaTipos}>
              <option value="">Tipo do evento</option>
              {tipos.map((tipo) => (
                <option value={tipo.id} key={tipo.id}>
                  {tipo.descricao}
                </option>
              ))}
            </select>

            <select onChange={(e) => setEscolhaRamo(e.target.value)} value={escolhaRamo}>
              <option value="">Tipo do serviço</option>
              {ramo.map((ramo) => (
                <option value={ramo.descricao} key={ramo.id}>
                  {ramo.descricao}
                </option>
              ))}
            </select>

            <select onChange={(e) => setEscolhaTema(e.target.value)} value={escolhaTema}>
              <option value="">Tema/Estilo</option>
              {temas.map((tema) => (
                <option value={tema.id} key={tema.id}>
                  {tema.descricao}
                </option>
              ))}
            </select>
            <button onClick={() => filtrar()}>Filtrar Fornecedores</button>
          </div>
        </div>
      </div>
    </section>
  );
}
