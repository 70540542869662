import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import Footer from "../../../components/Footer/Footer";
import { useForm } from "react-hook-form";
import api from "../../../Services/api";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";

export function ResetPassword() {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const { register, handleSubmit, setValue, reset, formState: { isSubmitting } } = useForm();
  const [loading, setLoading] = useState(true);
  const [validMessage, setValidMessage] = useState(undefined);
  useEffect(() => {
    const validateToken = async () => {
      if (!token) return;
      try {
        setValidMessage(undefined);
        const response = await api.get(`/consumidor/reset_token/${token}`);
        setValue("email", response.data.email);
      } catch (err) {
        if (err.response.status === 500) {
          setValidMessage("Token inválido")
        } else {
          setValidMessage("Token expirado")
        }
      } finally {
        setLoading(false);
      }
    }
    validateToken()
  }, [token]);

  const history = useHistory();
  const [error, setError] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const onSubmit = async (data) => {
    try {
      setError(undefined);
      setMessage(undefined);
      if (data.password !== data.confirm_password) {
        setError("As senhas não são iguais");
        return;
      }
      await api.post("/consumidor/new_password", {
        email: data.email,
        password: data.password
      })

      setMessage("Senha redefinida com suceesso, você será direcionado para o login em breve")
      setTimeout(() => {
        history.push("/login-consumidor");
      }, 2000);
    } catch (error) {
      setError(error.response?.data.message || error.message);
      console.error("Erro ao salvar senha:", error.response?.data || error.message);
    }
  };

  if (loading) {
    return (
      <div className="container-fluid mt-60">
        <div className="row mt-20">
          <div className="col-md-12 text-center">
            <h1 className="mt-30 login__title">Validando token...</h1>
          </div>
        </div>
        <div className="row mt-120">
          <Footer></Footer>
        </div>
      </div>
    );
  }

  if (validMessage) {
    return (
      <div className="container-fluid mt-60">
        <div className="row mt-20">
          <div className="col-md-12 text-center">
            <h1 className="mt-30 login__title">{validMessage}</h1>
          </div>
        </div>
        <div className="row mt-120">
          <Footer></Footer>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-60">
        <div className="row mt-20">
          <div className="col-md-12 text-center">
            <h1 className="mt-30 login__title">Redefinir Senha de Acesso</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 offset-md-4 mt-30">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group text-center login-block">
                <label htmlFor="usuario">Email</label>

                <div className="user">
                  <FontAwesomeIcon className="user-icon" icon={faUser} />
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    readOnly
                    {...register("email", {
                      required: "O email é obrigatório",
                    })}
                  />
                </div>
              </div>

              <div className="form-group text-center login-block">
                <label htmlFor="senha">Senha</label>

                <div className="user">
                  <FontAwesomeIcon className="user-icon" icon={faLock} />
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    {...register("password", {
                      required: "A senha é obrigatória",
                    })}
                  />
                </div>
              </div>

              <div className="form-group text-center login-block">
                <label htmlFor="confirma_senha">Confirmar Senha</label>

                <div className="user">
                  <FontAwesomeIcon className="user-icon" icon={faLock} />
                  <input
                    type="password"
                    className="form-control"
                    id="confirm_password"
                    {...register("confirm_password", {
                      required: "Confirmar senha é obrigatório",
                    })}
                  />
                </div>
              </div>

              {error && (
                <p className="error-message" style={{
                  textAlign: "center"
                }}>{error}</p>
              )}

              {message && (
                <p className="error-message" style={{
                  textAlign: "center",
                  color: "green"
                }}>{message}</p>
              )}

              <div class="form-group text-center">
                <button className="btn btn-login" disabled={isSubmitting}>
                  {isSubmitting ? "Salvando" : "Salvar"}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="row mt-120">
          <Footer></Footer>
        </div>
      </div>
    </>
  );
}
