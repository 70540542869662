import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import fotoDetault from "../../../Assets/defaultuser.jpeg";
import "./css/index.css";

import newBaseApi from "../../../Services/newBaseApi";

export default function PerfilConsumidor() {
  const [perfil, setPerfil] = useState({
    consumidor_nome: "",
    consumidor_celular: "",
    consumidor_telefone: "",
    consumidor_sexo: "",
    consumidor_data_nascimento: "",
    consumidor_foto: "",
    email: "",
    consumidor_cidade_id: "",
    consumidor_estado_id: "",
  });
  const [fotoOriginal, setFotoOriginal] = useState(null);

  const [senha, setSenha] = useState({
    password: "",
    confirm_password: "",
  });

  const [editando, setEditando] = useState(false);

  useEffect(() => {
    carregarPerfil();
  }, []);

  async function carregarPerfil() {
    try {
      const response = await newBaseApi.get("/consumidor/user");
      setPerfil({
        consumidor_nome: response.data.consumidor_nome || "",
        consumidor_celular: response.data.consumidor_celular || "",
        consumidor_telefone: response.data.consumidor_telefone || "",
        consumidor_sexo: response.data.consumidor_sexo || "",
        consumidor_data_nascimento:
          response.data.consumidor_data_nascimento || "",
        consumidor_foto: response.data.consumidor_foto || fotoDetault,
        email: response.data.email || "",
      });
      setFotoOriginal(response.data.consumidor_foto || fotoDetault);
      toast.success("Perfil carregado com sucesso!");
    } catch (error) {
      toast.error("Erro ao carregar perfil do consumidor.");
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPerfil((prevPerfil) => ({ ...prevPerfil, [name]: value }));
  };

  const handleSenhaChange = (e) => {
    const { name, value } = e.target;
    setSenha((prevSenha) => ({ ...prevSenha, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPerfil((prevPerfil) => ({
          ...prevPerfil,
          consumidor_foto: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const salvarAlteracoes = async () => {
    const payload = {
      consumidor_nome: perfil.consumidor_nome,
      email: perfil.email,
      consumidor_celular: perfil.consumidor_celular,
      consumidor_telefone: perfil.consumidor_telefone || null,
      consumidor_sexo: perfil.consumidor_sexo || null,
      consumidor_data_nascimento: perfil.consumidor_data_nascimento || null,
      consumidor_foto:
        perfil.consumidor_foto === fotoOriginal ? null : perfil.consumidor_foto,
    };

    try {
      await newBaseApi.put("/consumidor/alterarPerfil", payload);
      toast.success("Perfil atualizado com sucesso!");
      setEditando(false);
      carregarPerfil();
    } catch (error) {
      toast.error("Erro ao salvar as alterações.");
    }
  };

  const salvarSenha = async () => {
    if (senha.password !== senha.confirm_password) {
      toast.error("As senhas não coincidem.");
      return;
    }

    try {
      await newBaseApi.put("/consumidor/alterarPerfil", {
        email: perfil.email,
        password: senha.password,
        confirm_password: senha.confirm_password,
        consumidor_nome: perfil.consumidor_nome,
      });
      toast.success("Senha atualizada com sucesso!");
      setSenha({ password: "", confirm_password: "" });
    } catch (error) {
      toast.error("Erro ao atualizar a senha.");
    }
  };

  return (
    <div className="perfil-container">
      <Toaster />
      <h1 className="perfil-titulo">Meu Perfil</h1>
      <div className="perfil-content">
        <div className="perfil-left">
          <img
            src={perfil.consumidor_foto || fotoDetault}
            alt="Perfil"
            className="perfil-foto"
          />
          {editando && (
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="foto-input"
            />
          )}
        </div>
        <div className="perfil-center">
          {[
            { label: "Nome", name: "consumidor_nome", value: perfil.consumidor_nome },
            { label: "Celular", name: "consumidor_celular", value: perfil.consumidor_celular },
            { label: "Telefone", name: "consumidor_telefone", value: perfil.consumidor_telefone },
            { label: "Sexo", name: "consumidor_sexo", value: perfil.consumidor_sexo },
            {
              label: "Data de Nascimento",
              name: "consumidor_data_nascimento",
              value: perfil.consumidor_data_nascimento,
              type: "date",
            },
          ].map((campo) => (
            <div className="perfil-campo" key={campo.name}>
              <label>{campo.label}:</label>
              {editando ? (
                <input
                  type={campo.type || "text"}
                  name={campo.name}
                  value={campo.value}
                  onChange={handleInputChange}
                />
              ) : (
                <p>{campo.value || "Não informado"}</p>
              )}
            </div>
          ))}
          <div className="perfil-campo">
            <label>Email:</label>
            <p className="disabled">{perfil.email || "Não informado"}</p>
          </div>
          <div className="perfil-acoes">
            {editando ? (
              <>
                <button onClick={salvarAlteracoes} className="btn-salvar">
                  Salvar
                </button>
                <button
                  onClick={() => setEditando(false)}
                  className="btn-cancelar"
                >
                  Cancelar
                </button>
              </>
            ) : (
              <button onClick={() => setEditando(true)} className="btn-editar">
                Editar Perfil
              </button>
            )}
          </div>
        </div>
        <div className="perfil-right">
        <h2>Alterar Senha</h2>
        <div className="perfil-campo">
          <label>Nova Senha:</label>
          <input
            type="password"
            name="password"
            value={senha.password}
            onChange={handleSenhaChange}
          />
        </div>
        <div className="perfil-campo">
          <label>Confirmar Nova Senha:</label>
          <input
            type="password"
            name="confirm_password"
            value={senha.confirm_password}
            onChange={handleSenhaChange}
          />
        </div>
        <div className="perfil-acoes">
          <button onClick={salvarSenha} className="btn-salvar">
            Alterar Senha
          </button>
          <button
            onClick={() => setSenha({ password: "", confirm_password: "" })}
            className="btn-cancelar"
          >
            Cancelar
          </button>
        </div>
      </div>
      </div>    
    </div>
  );
}
