import React from "react";
import { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import Anuncio from "./pages/Anuncio";
import CadastroConsumidor from "./pages/CadastroConsumidor";
import CriarMeuEvento from "./pages/CriarMeuEvento";
import Eventos from "./pages/Eventos";
import EventosPublicos from "./pages/EventosPublicos";
import listagemFiltro from "./pages/Filtro/components/listagem";
import Home from "./pages/Home";
import QuemSomosAnunciante from "./pages/QuemSomosAnunciante";
import QuemSomosConsumidor from "./pages/QuemSomosConsumidor";
import CadastroAnunciante from "./pages/cadastroAnunciante";
import Convite from "./pages/convite";
import NotFound from "./pages/notFound";
import QuemSomos from "./pages/quemSomos";
import PoliticaDePrivacidadeApp from "./pages/PoliticaDePrivacidade/PoliticaApp";
import { SignUp } from "./pages/AuthConsumidor/SignUp";
import { SignIn } from "./pages/AuthConsumidor/SignIn";
import Profile from "./pages/Profile";
import { AuthContext } from "./context/AuthContext";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import SucessPayment from "./pages/sucessPayment";
import { ResetPassword } from "./pages/AuthConsumidor/ResetPassword";
import Dashboard from "./pages/Dashboard";
import DetalhesEvento from "./pages/Dashboard/DetalhesEvento";
import PerfilConsumidor from "./pages/Dashboard/PerfilConsumidor";
import { AppLinks } from "./pages/AppLinks";

export default function Routes() {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route
        path="/politica-privacidade/app"
        exact
        component={PoliticaDePrivacidadeApp}
      />

      <Route path="/filter/:dados" component={listagemFiltro} />

      <Route path="/anuncio/:id/:idAnunciante" component={Anuncio} />
      <Route path="/evento-publico/:id" exact component={Eventos} />
      <Route path="/eventos-publicos" component={EventosPublicos} />
      <Route path="/login-consumidor" component={SignIn} />
      {/* <Route path="/login-anunciante" component={LoginAnunciante} /> */}
      <Route path="/convite" component={Convite} />
      <Route path="/cadastro-anunciante" component={CadastroAnunciante} />
      <Route path="/cadastro-consumidor" component={CadastroConsumidor} />
      <Route path="/quem-somos" component={QuemSomos} />
      <Route path="/quem-somos-consumidor" component={QuemSomosConsumidor} />
      <Route path="/quem-somos-anunciante" component={QuemSomosAnunciante} />
      <Route path="/criar-meu-evento" component={CriarMeuEvento} />
      <Route path="/sign-up" component={SignUp} />
      <Route path="/reset-password" component={ResetPassword} />

      <Route
        path="/profile"
        render={() =>
          isAuthenticated ? <Profile /> : <Redirect to="/login-consumidor" />
        }
      />
      <Route path="/sucesso-pagamento" component={SucessPayment} />
      <Route path="/dashboard/detalhes-evento/:id" component={DetalhesEvento} />
      <Route path="/dashboard" exact component={Dashboard} />

      <Route path="/dashboard/perfil" component={PerfilConsumidor} />
      <Route path="/app-links" component={AppLinks} />

      <Route path="*" component={NotFound} />
    </Switch>
  );
}
