import React, { useEffect, useState } from "react";
import "./css/index.css";
import { useUser } from "../../context/AuthContext";
import newBaseApi from "../../Services/newBaseApi";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/Format";

export default function Dashboard() {
  const [eventos, setEventos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { user } = useUser();

  useEffect(() => {
    if (user) {
      fetchEventos(user.id);
    }
  }, [user]);

  const fetchEventos = async (id) => {
    try {
      setLoading(true);
      const response = await newBaseApi.get(
        `/consumidor/eventos/list/${user.id}`
      );
      setEventos(response.data.eventos);
    } catch (err) {
      console.error("Erro ao buscar eventos:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="eventos-container">
        <header className="eventos-header">
          <h2>Seus eventos</h2>
          <a href="/criar-meu-evento">
            <button className="btn-cadastrar-evento">Cadastrar Evento</button>
          </a>
        </header>

        {loading ? (
          <p>Carregando eventos...</p>
        ) : error ? (
          <p>Erro ao carregar os eventos: {error.message}</p>
        ) : (
          <section className="grid-list">
            {eventos.length > 0 ? (
              eventos.map((evento) => (
                <div key={evento.id} className="evento-card">
                  <div className="evento-info">
                    <p>
                      <strong>Nome:</strong> {evento.nome}
                    </p>
                    <p>
                      <strong>Status:</strong> {evento.status}
                    </p>
                    <p>
                      <strong>Data:</strong> {formatDate(evento.date)}
                    </p>
                  </div>
                  <Link
                    to={`/dashboard/detalhes-evento/${evento.id}`}
                    state={{ id: evento.id, nome: evento.nome }}
                  >
                    <button className="btn-visualizar-evento">
                      Visualizar Evento
                    </button>
                  </Link>
                </div>
              ))
            ) : (
              <p>Nenhum evento encontrado.</p>
            )}
          </section>
        )}
      </div>
    </div>
  );
}
