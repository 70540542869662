import React from "react";
import { FiMail } from "react-icons/fi";
import { IoMdArrowDropdown } from "react-icons/io";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "../../Assets/LUPPNG.png";
import facebook from "../../Assets/facebook.png";
import instagram from "../../Assets/instagram.png";
import "../header/header.css";
import {useUser} from "../../context/AuthContext.js"

export default function Header() {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { user } = useUser(); // Hook para verificar se o usuário está logado
  const isMyEventScreen = pathname === "/criar-meu-evento";

  function openMenu(e) {
    const menu = document.querySelector(".menu-container-responsive");
    const body = document.querySelector("body");
    e.preventDefault();
    menu.classList.add("menu_open");
    body.classList.add("menu-body");
  }

  function closeMenu(e) {
    const menu = document.querySelector(".menu-container-responsive");
    const body = document.querySelector("body");
    e.preventDefault();
    menu.classList.remove("menu_open");
    body.classList.remove("menu-body");
  }

  function dropdownOpen(e) {
    const dropdown = document.querySelector(".dropdown-mobile");
    e.preventDefault();
    dropdown.classList.toggle("dropdown_open");
  }

  function dropdownOpenLast(e) {
    const dropdown = document.querySelector(".dropdown-mobile-last");
    e.preventDefault();
    dropdown.classList.toggle("dropdown_open");
  }

  return (
    <header
      id="fix"
      className="header"
      style={{
        boxShadow: isMyEventScreen ? "none" : "0px 2px 22px 0px #a7a7a7",
      }}
    >
      <div>
        {/* Header Desktop */}
        <div className="container header-desktop">
          <div>
            <Link to="/">
              {" "}
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="menu-container">
            {/* Botão de autenticação */}
            <div className="auth">
              {user ? (
                <Link className="auth-link" to="/dashboard">
                  Dashboard
                </Link>
              ) : (
                <Link className="auth-link" to="/login-consumidor">
                  Entrar
                </Link>
              )}
            </div>
            <div className="contato">
              <div>
                <FiMail color="#a4416f" size="22" />
                <span>contato@lup.com.br</span>
              </div>

              <div className="redes-sociais">
              <a href="https://www.facebook.com/plataformalup/" target="_blank" rel="noopener noreferrer">
                <img src={facebook} alt="redes sociais" />
              </a>
              <a href="https://www.instagram.com/lup.com.br/" target="_blank" rel="noopener noreferrer">
                <img src={instagram} alt="redes sociais" />
              </a>

              </div>
            </div>
            <div className="menu">
              <nav>
                <Link to="/">Home</Link>
                {/* Menu suspenso de cadastro */}
                <div className="dropdown">
                  <h2>
                    Cadastro
                    <span>
                      <IoMdArrowDropdown />
                    </span>
                  </h2>
                  <div className="dropdowncontent">
                    <Link className="linkDropdown" to="/sign-up">
                      Consumidor
                    </Link>
                  </div>
                </div>
                <Link to="/quem-somos">Quem somos</Link>
                <a href="mailto:sac@lup.com.br">Contato</a>
                <Link to="/criar-meu-evento">Criar meu evento</Link>
              </nav>
            </div>
          </div>
        </div>
        {/* Header Mobile */}
        <div className="header-mobile">
          <div className="logo">
            <Link to="/">
              {" "}
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="header-esquerda">
            <nav className="menu">
              <span className="btnMenu btnMenuOpen" onClick={openMenu}>
                menu
              </span>

              <div className="menu-container menu-container-responsive">
                <div onClick={closeMenu} className="overlay"></div>
                <div className="menu-header">
                  <div className="logo">
                    <Link to="/">
                      {" "}
                      <img src={Logo} alt="logo" />
                    </Link>
                  </div>
                  <div className="navbar-close">
                    <span className="btnMenu btnMenuClose" onClick={closeMenu}>
                      menu
                    </span>
                  </div>
                </div>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  {/* Menu suspenso de cadastro no mobile */}
                  <li onClick={dropdownOpenLast} style={{ cursor: "pointer" }}>
                    <span>
                      Cadastro
                      <span>
                        <IoMdArrowDropdown />
                      </span>
                    </span>

                    <div className="dropdown-mobile-last">
                      <div>
                        <Link to="/sign-up">Consumidor</Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="/">Quem somos</a>
                  </li>
                  <li>
                    <a href="mailto:sac@lup.com.br">Contato</a>
                  </li>
                  <li>
                    <Link to="/criar-meu-evento">Criar meu evento</Link>
                  </li>
                </ul>
                <div className="footer-menu">
                  <div>
                    <FiMail color="#a4416f" size="14" />
                    <span>contato@lup.com.br</span>
                  </div>
                </div>
              </div>
            </nav>
            <div className="menu-contato-responsive">
              <div className="redes-sociais">
              <a href="https://www.facebook.com/plataformalup/" target="_blank" rel="noopener noreferrer">
                <img src={facebook} alt="redes sociais" />
              </a>
              <a href="https://www.instagram.com/lup.com.br/" target="_blank" rel="noopener noreferrer">
                <img src={instagram} alt="redes sociais" />
              </a>

              </div>
              <div>
                <FiMail color="#a4416f" size="22" />
                <span>contato@lup.com.br</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
