import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useUser } from "../../context/AuthContext";
import "./css/index.css";
import logo from "../../Assets/logo.png";

export default function DashboardHeader() {
  const { user, logout } = useUser();
  const history = useHistory();

  const handleLogout = () => {
    logout();
    history.push("/login-consumidor");
  };

  return (
    <header className="dashboard-header-container">
      <div className="dashboard-header-content">
        {/* Logo como um link para a página inicial */}
        <NavLink to="/" className="dashboard-logo-link">
          <img src={logo} alt="Logo" className="dashboard-logo" />
        </NavLink>

        <nav className="dashboard-nav">
          {/* Link para a página de edição de perfil */}
          <NavLink
            to="/dashboard/perfil"
            className="dashboard-nav-link"
            activeClassName="active-link"
          >
            Perfil
          </NavLink>
          <button className="logout-button" onClick={handleLogout}>
            Logout
          </button>
        </nav>
      </div>
    </header>
  );
}
