import "./main.css";
import AndroidQRCode from "../../Assets/android-qrcode.svg";
import IOSQRCode from "../../Assets/ios-qrcode.svg";
import Footer from "../../components/Footer/Footer";

export const AppLinks = () => {
  return (
    <>
      {" "}
      <div className="container-fluid" style={{ marginBottom: 100 }}>
        <div style={{ width: "100%" }}>
          <section
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 32,
            }}
          >
            <img
              alt="LUP Profissionais Logo"
              src={require("../../Assets/new-icon-lup.png")}
              style={{
                width: 160,
                height: 160,
                border: "1px solid rgb(164, 164, 164)",
                borderRadius: 16,
                boxShadow: "10px 10px 30px 0px rgba(0,0,0,0.20)",
              }}
            />
            <h1 style={{ color: "#b82d63", fontWeight: 700, marginTop: 12 }}>
              LUP Profissionais
            </h1>
            <h3>Já disponível para Android e IOS</h3>
            <p style={{ fontSize: 18, maxWidth: 800, color: "#808080" }}>
              Baixe agora o APP destinado a fornecedores, anunciantes e
              profissionais. Crie seu Anúncio, genrencie orçamentos e se
              comunique com seus clientes!
            </p>

            <section id="os-links-section">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h2 style={{ color: "#04ADBE", fontWeight: 700 }}>Android</h2>
                <img
                  src={AndroidQRCode}
                  alt="Android QR Code"
                  style={{ width: 240, height: 240 }}
                />{" "}
                <p style={{ marginTop: 12, marginBottom: -3 }}>
                  Clique abaixo para acessar o Link:
                </p>
                <a
                  href="https://play.google.com/store/apps/details?id=com.lup.meuapp&hl=pt_BR"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    style={{ width: 200 }}
                    alt="Baixar na Google Play"
                    src={require("../../Assets/google-play-download-button.png")}
                  />
                </a>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h2 style={{ color: "#04ADBE", fontWeight: 700 }}>IOS</h2>
                <img
                  src={IOSQRCode}
                  alt="IOS QR Code"
                  style={{ width: 240, height: 240 }}
                />
                <p style={{ marginTop: 12, marginBottom: 5 }}>
                  Clique abaixo para acessar o Link:
                </p>
                <a
                  href="https://apps.apple.com/br/app/lup-profissionais/id6680028570?l=pt-BR"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    style={{ width: 200 }}
                    alt="Baixar na App Store"
                    src={require("../../Assets/app-store-download-button.png")}
                  />
                </a>
              </div>
            </section>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

