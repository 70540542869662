import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Footer from "../../components/Footer/Footer";
import "./style.css";

const QuemSomosAnunciante = () => (
  <div className="container-fluid">
    <div class="row text-justify">
      <section>
        <div class="col-lg-10 offset-lg-1 offset-lg-1 mt-30">
          <h4 className="text-center">Como funciona – Para Anunciantes</h4>
        </div>

        <div class="col-lg-10 offset-lg-1">
          <p>
            O Lup é uma plataforma democrática feita especialmente para
            aproximar você que fornece serviços e produtos para eventos de seus
            clientes. Através de um sistema simples e prático de utilizar, seus
            clientes poderão encontrá-lo, pedir seu orçamento e contratá-lo sem
            burocracias, e tudo isso feito através de uma plataforma
            transparente e que auxilia o fornecedor a manter seus orçamentos
            organizados.
          </p>
          <p>
            Além de tudo isso, não precisa se preocupar com valores.{" "}
            <u> Os valores são fixos</u> (não ficamos com percentual nenhum da
            sua venda) e o que define a ordem de exposição é a sua qualidade e
            profissionalismo (veja ranqueamento). Ou seja, quanto maior a sua
            pontuação, melhor seu posicionamento na plataforma!
          </p>
        </div>

        <div class="col-lg-10 offset-lg-1 offset-lg-1 mt-30">
          <h5 className="text-center">Sistema de Anúncios</h5>
        </div>

        <div class="col-lg-10 offset-lg-1">
          <p>
            Para que o seu cliente encontre EXATAMENTE o que você oferece, aqui
            no LUP os anúncios são separados por serviço. Fica muito mais fácil
            vender assim!
          </p>
          <p>E para fazer o meu cadastro como anunciante. Como faço?</p>
        </div>

        <div class="col-lg-10 offset-lg-1 offset-lg-1 mt-30">
          <h5 className="text-center">Cadastro de Anunciante</h5>
        </div>

        <div class="col-lg-10 offset-lg-1">
          <p>Então vamos começar!</p>
          <p>Para ver como fazer parte de tudo isso, siga os passos abaixo:</p>
          <ul className="p-4">
            <li>
              <a href="/app-links">
                Acesse esse link para encontrar os links para o aplicativo
              </a>
            </li>
            <li>
              Selecione o sistema operacional e acesse a loja do seu celular
              através do link ou QRCode.
            </li>
            <li>Faça o download do aplicativo da Lup para profissionais.</li>
            <li>Clique em salvar;</li>
            <li>Faça o cadastro de sua empresa seguindo as telas do App.</li>
            <li>Aceite os termos de serviço para usuários gratuitos.</li>
          </ul>
          <p>
            Você terá automaticamente acesso às ferramentas gratuitas do
            aplicativo. Para dar um UP no seu negócio e ter seu anúncio na
            plataforma, siga os passos do Cadastro de Anúncio.
          </p>
        </div>
      </section>

      <section
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div class="offset-lg-1 purple-courtain  mt-30">
          <h5 className="text-center">Cadastro de Anúncio</h5>

          <ul className="pl-4">
            <li>Faça o login no aplicativo Lup Profissionais.</li>
            <li>No canto superior esquerdo, acesse o menu.</li>
            <li>Selecione o botão “Atualizar Plano”</li>
            <li>Selecione o botão “Quero ser PRO”</li>
            <li>Selecione o plano desejado, três, seis ou doze meses.</li>
            <li>Selecione o botão “Quero ser PRO”</li>
            <li>Aceite os termos do “Usuário PRO”</li>
            <li>Finalize o pagamento através do gateway de nosso parceiro</li>
          </ul>
        </div>
      </section>

      <section>
        <div class="col-lg-10 offset-lg-1 mt-60">
          <p>
            Lembre-se que você pode alterar estes dados sempre que necessário.
          </p>
          <p>
            Após a finalização, nossa equipe irá fazer a verificação do anúncio
            e liberá-lo para as buscas. Caso ocorra alguma discrepância com as
            políticas do site, você será informado por e-mail.
          </p>
        </div>
      </section>

      <section class="">
        <div class="blue-courtain mt-60 col-lg-10 offset-lg-1 container-pergunta">
          <p class="container-pergunta__pergunta">
            Pergunta: Tenho alguma carência ou estou me comprometendo a
            contratar mais meses?
          </p>
          <p class="container-pergunta__resposta">
            Não. Aqui no Lup não existem armadilhas. Tudo é claro e
            transparente. Fazendo seu cadastro você terá exatamente o período
            contratado de anúncio assim que o pagamento for compensado (ou mais,
            no caso de alguma promoção). Se após este período não quiser
            continuar não é necessário fazer a renovação ou ampliação do plano
            contratado.
            <br />E não se preocupe, vamos avisá-lo quando seu plano estiver
            chegando ao fim para que você tome a melhor decisão para seu
            negócio.
          </p>
        </div>
      </section>

      <section class="">
        <div class="blue-courtain col-lg-10 offset-lg-1 ">
          <p class="container-pergunta__pergunta">
            Pergunta: Como recebo do consumidor?
          </p>
          <p class="mt-15">
            Resposta: O Lup trabalha apenas conectando você com seus clientes.
            Não recebemos nenhuma comissão e as garantias (se aplicáveis) são
            fornecidas exclusivamente pelo fornecedor do serviço ou bem. É
            necessário que o meio de pagamento seja acertado diretamente com o
            consumidor.
          </p>
        </div>
      </section>

      <section>
        <div class="col-lg-10 offset-lg-1 offset-lg-1 mt-30">
          <h5 className="text-center">Como Funciona o RANQUEAMENTO</h5>
        </div>
        <div class="col-lg-10 offset-lg-1 mt-15">
          <p>
            O processo de ranqueamento do LUP foi criado para premiar, acima de
            tudo, a qualidade de atendimento. Para isso, utilizamos quatro
            variáveis básicas. A que tem mais peso é a qualificação dada pelo
            cliente, que é a nota que ele deu para seu atendimento. Após isso,
            para critério de desempate, é utilizado o número de avaliações
            realizadas, o tempo de resposta de orçamento e por fim o tempo de
            cadastro contínuo. Abaixo seguem descrições mais detalhadas.
          </p>
          <p className="mt-15">
            <strong>1º - Qualificação do Cliente: </strong> Após a prestação do
            serviço ou entrega do produto o consumidor pode fazer uma avaliação
            de 1 à 5 estrelas para o fornecedor. É feita então uma média para se
            calcular o número de estrelas que o fornecedor possui.
          </p>
          <p className="mt-15">
            <strong>2º - Número de Avaliações: </strong>Aqui é contada as vezes
            que algum cliente avaliou o produto que consumiu ou um serviço que
            contratou do fornecedor. Por isso que é importante solicitar a
            avaliação depois de fazer a entrega para seu cliente.
          </p>
          <p className="mt-15">
            <strong>3º - Contagem do Tempo para resposta de Orçamento: </strong>
            Ninguém gosta de ficar esperando dias para receber um orçamento.
            Então neste quesito fazemos a média do tempo de resposta de
            orçamento, e os fornecedores mais eficientes terão prioridade no
            aparecimento nas buscas.
          </p>
          <p>
            O tempo de contagem de tempo será das 08h00 até as 19h00. Não serão
            somados os horários a partir das 19h00 até as 08h00.{" "}
          </p>

          <div class="col-lg-10 offset-lg-1 green-courtain offset-lg-1 mt-30">
            <p>Exemplo:</p>
            Foi cadastrado um pedido de orçamento as 18h00. O anunciante
            respondeu enviando ao orçamento às 20h00. Será contado como levado
            1h00 para resposta do orçamento, pois das 19h00 até as 20h00 não
            deve ser contado. <br />
            Se ele responder as 03h00 da manhã, também é contado 1h00. Porém, se
            ele fizer a resposta ao orçamento no dia seguinte às 10h00, serão
            contabilizadas 03h00, uma do dia anterior e duas do dia atual.
          </div>
          <p className="mt-15">
            <strong>4º - Tempo de Cadastro Contínuo: </strong>
            Por fim, e apenas se os últimos três quesitos forem iguais, o tempo
            de cadastro de anunciante vale para desempate de colocações. Sendo
            que nesta questão, o anunciante com mais tempo corrido de contrato
            ativo fica na dianteira.
          </p>
        </div>
      </section>

      <div className="container mb-60">
        <div className="row">
          <div className="col-lg-12 mt-30">
            <Link to="./quem-somos">
              {" "}
              <button className="btn btn-default">Voltar</button>
            </Link>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
);

export default QuemSomosAnunciante;

