import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
  // baseURL: 'http://18.221.183.124/lup_backend_master/public/api'
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();

  if (token) {
    config.headers["Authorization"] = `bearer ${token}`;
  }
  return config;
});

// Trocar url do img.js

export default api;
