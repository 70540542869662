import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../Services/api";
import "./css/Anuncio.css";
import Tab from "./tabs";
import DefaultUser from "../../Assets/defaultuser.jpeg";
import Footer from "../../components/Footer/Footer";
import newBaseApi from "../../Services/newBaseApi";

export default function Anuncio() {
  let params = useParams();

  const [data, setData] = useState([]);

  useEffect(() => {
    fixLoad();
    async function loadExibir() {
      const response = await newBaseApi.get(
        `/anunciante/public-data/${params.id}`
      );
      setData(response.data);
    }

    loadExibir();
  }, [params.id]);

  const fixLoad = () => {
    document.querySelector("html").style = "scroll-behavior: unset;";
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  };

  return (
    <>
      <div className="container-anuncio">
        {/* <div className="btn-anuncio">
                <button onClick={handleShow}>Solicitar orçamento</button>
            </div> */}
        <div className="main-anuncio">
          <div className="imagem-anuncio">
            {data.logo ? <img src={data.logo} /> : <img src={DefaultUser} />}
          </div>
          <h1>{data.nome}</h1>
          <p>{data.descricao}</p>
        </div>
        <div></div>
        <div className="container-tabs">
          <Tab data={data} />
        </div>
      </div>
      {/* <OrcamentoModal
        show={show}
        setShow={setShow}
        id={params.idAnunciante}
        telefoneOrcamento={data.celular}
      /> */}
      <Footer />
    </>
  );
}
